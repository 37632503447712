(function() {
    'use strict';

    angular
        .module('infiltrationApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('infiltration-entry', {
            parent: 'entity',
            url: '/infiltration-entry',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'InfiltrationEntries'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/infiltration-entry/infiltration-entries.html',
                    controller: 'InfiltrationEntryController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
            }
        })
        .state('infiltration-entry-detail', {
            parent: 'entity',
            url: '/infiltration-entry/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'InfiltrationEntry'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/infiltration-entry/infiltration-entry-detail.html',
                    controller: 'InfiltrationEntryDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'InfiltrationEntry', function($stateParams, InfiltrationEntry) {
                    return InfiltrationEntry.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'infiltration-entry',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('infiltration-entry-detail.edit', {
            parent: 'infiltration-entry-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/infiltration-entry/infiltration-entry-dialog.html',
                    controller: 'InfiltrationEntryDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['InfiltrationEntry', function(InfiltrationEntry) {
                            return InfiltrationEntry.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('infiltration-entry.new', {
            parent: 'infiltration-entry',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/infiltration-entry/infiltration-entry-dialog.html',
                    controller: 'InfiltrationEntryDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                area: null,
                                basecaseLeakage: null,
                                retrofittedLeakage: null,
                                costElectricity: null,
                                costGas: null,
                                areaUnit: null,
                                leakageUnit: null,
                                gasCostUnits: null,
                                descriptionText: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('infiltration-entry', null, { reload: 'infiltration-entry' });
                }, function() {
                    $state.go('infiltration-entry');
                });
            }]
        })
        .state('infiltration-entry.edit', {
            parent: 'infiltration-entry',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/infiltration-entry/infiltration-entry-dialog.html',
                    controller: 'InfiltrationEntryDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['InfiltrationEntry', function(InfiltrationEntry) {
                            return InfiltrationEntry.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('infiltration-entry', null, { reload: 'infiltration-entry' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('infiltration-entry.delete', {
            parent: 'infiltration-entry',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/infiltration-entry/infiltration-entry-delete-dialog.html',
                    controller: 'InfiltrationEntryDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['InfiltrationEntry', function(InfiltrationEntry) {
                            return InfiltrationEntry.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('infiltration-entry', null, { reload: 'infiltration-entry' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
