/**
 * Created by g9a on 9/22/16.
 */
(function() {
    'use strict';
    angular
        .module('infiltrationApp')
        .factory('EnergyCalcQuery', EnergyCalcQuery);

    EnergyCalcQuery.$inject = ['$resource'];

    function EnergyCalcQuery ($resource) {
        var resourceUrl =  'api/energy-calc/map_location_id/:map_location_id/building_type_id/:building_type_id';

        return $resource(resourceUrl, {map_location_id: '@map_location_id', building_type_id: '@building_type_id'}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });
    }
})();
