(function() {
    'use strict';

    angular
        .module('infiltrationApp')
        .controller('RawDataController', RawDataController);

    RawDataController.$inject = ['$scope', '$state', 'RawData'];

    function RawDataController ($scope, $state, RawData) {
        var vm = this;
        
        vm.rawData = [];

        loadAll();

        function loadAll() {
            RawData.query(function(result) {
                vm.rawData = result;
            });
        }
    }
})();
