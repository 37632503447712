/**
 * Created by g9a on 2/5/16.
 */
(function() {
    'use strict';
    angular
        .module('infiltrationApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('infiltration', {
                parent: 'app',
                url: '/infiltration',
                // data: {
                //     authorities: ['ROLE_ADMIN', 'ROLE_USER']
                // },
                views: {
                    'content@': {
                        templateUrl: 'app/infiltration/infiltration.html',
                        controller: 'InfiltrationController',
                        controllerAs: 'vm'
                    }
                }
            });
        $stateProvider.state('infiltrationcalculation', {
            parent: 'app',
            url: '/infiltrationcalculation',
            // data: {
            //     authorities: ['ROLE_ADMIN', 'ROLE_USER']
            // },
            views: {
                'content@': {
                    templateUrl: 'app/infiltration/infiltrationcalculation.html',
                    controller: 'InfiltrationCalculationController',
                    controllerAs: 'vm'
                }
            }
        })
    }
})();
