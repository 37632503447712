(function() {
    'use strict';

    angular
        .module('infiltrationApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('energy-calc', {
            parent: 'entity',
            url: '/energy-calc',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'EnergyCalcs'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/energy-calc/energy-calcs.html',
                    controller: 'EnergyCalcController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
            }
        })
        .state('energy-calc-detail', {
            parent: 'entity',
            url: '/energy-calc/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'EnergyCalc'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/energy-calc/energy-calc-detail.html',
                    controller: 'EnergyCalcDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'EnergyCalc', function($stateParams, EnergyCalc) {
                    return EnergyCalc.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'energy-calc',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('energy-calc-detail.edit', {
            parent: 'energy-calc-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/energy-calc/energy-calc-dialog.html',
                    controller: 'EnergyCalcDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['EnergyCalc', function(EnergyCalc) {
                            return EnergyCalc.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('energy-calc.new', {
            parent: 'energy-calc',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/energy-calc/energy-calc-dialog.html',
                    controller: 'EnergyCalcDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                xElectric: null,
                                x2Electric: null,
                                xGas: null,
                                x2Gas: null,
                                descriptionText: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('energy-calc', null, { reload: 'energy-calc' });
                }, function() {
                    $state.go('energy-calc');
                });
            }]
        })
        .state('energy-calc.edit', {
            parent: 'energy-calc',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/energy-calc/energy-calc-dialog.html',
                    controller: 'EnergyCalcDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['EnergyCalc', function(EnergyCalc) {
                            return EnergyCalc.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('energy-calc', null, { reload: 'energy-calc' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('energy-calc.delete', {
            parent: 'energy-calc',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/energy-calc/energy-calc-delete-dialog.html',
                    controller: 'EnergyCalcDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['EnergyCalc', function(EnergyCalc) {
                            return EnergyCalc.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('energy-calc', null, { reload: 'energy-calc' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
