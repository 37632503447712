(function() {
    'use strict';

    angular
        .module('infiltrationApp')
        .controller('InfiltrationEntryController', InfiltrationEntryController);

    InfiltrationEntryController.$inject = ['$scope', '$state', 'InfiltrationEntry'];

    function InfiltrationEntryController ($scope, $state, InfiltrationEntry) {
        var vm = this;
        
        vm.infiltrationEntries = [];

        loadAll();

        function loadAll() {
            InfiltrationEntry.query(function(result) {
                vm.infiltrationEntries = result;
            });
        }
    }
})();
