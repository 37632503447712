(function () {
    'use strict';

    angular
        .module('infiltrationApp')
        .controller('HomeController', HomeController);

    HomeController.$inject = ['$scope', '$localStorage', 'Principal', 'LoginService', '$state', 'User'];

    function HomeController($scope, $localStorage, Principal, LoginService, $state, user) {
        var vm = this;

        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.$storage = $localStorage;
        vm.register = register;
        $scope.$on('authenticationSuccess', function () {
            getAccount();
        });

        getAccount();

        function getAccount() {
            Principal.identity().then(function (account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
                if (angular.isObject(vm.account)) {
                    initMarkers();
                    if (!vm.$storage.hasOwnProperty('infiltrationEntry')) {
                        // Initialize the wall
                        initInfiltrationEntry();
                    }
                    // if (!vm.$storage.hasOwnProperty('markers')) {
                    //     initMarkers();
                    // }
                    if (!vm.$storage.hasOwnProperty('infiltrationResult')) {
                        initInfiltrationResult();
                    }
//                    if (!vm.$storage.hasOwnProperty('areaUnits')) {
                        initUnits();
//                    }
                }
            });
        }

        function register() {
            $state.go('register');
        }

        function initInfiltrationEntry() {
            vm.$storage.infiltrationEntry = {};
        }

        function initInfiltrationResult() {
            vm.$storage.infiltrationResult = {};
        }

        function initMarkers() {
            vm.$storage.markers =

                [

                    {
                        "id": 1,
                        "country_id": 1,
                        "country": "United States",
                        "country_abbrev": "USA",
                        "state_id": 1,
                        "state_abbrev": "AL",
                        "state_name": "Alabama",
                        "city_state": "Birmingham, AL",
                        "city": "Birmingham",
                        "coords": {
                            "latitude": 33.527444,
                            "longitude": -86.799047
                        },
                        "cost_electricity": 0.11,
                        "cost_gas": 11.03
                    },

                    {
                        "id": 9,
                        "country_id": 1,
                        "country": "United States",
                        "country_abbrev": "USA",
                        "state_id": 2,
                        "state_abbrev": "AK",
                        "state_name": "Alaska",
                        "city_state": "Fairbanks, AK",
                        "city": "Fairbanks",
                        "coords": {
                            "latitude": 64.836531,
                            "longitude": -147.651745
                        },
                        "cost_electricity": 0.18,
                        "cost_gas": 8.01
                    },

                    {
                        "id": 19,
                        "country_id": 1,
                        "country": "United States",
                        "country_abbrev": "USA",
                        "state_id": 3,
                        "state_abbrev": "AZ",
                        "state_name": "Arizona",
                        "city_state": "Phoenix, AZ",
                        "city": "Phoenix",
                        "coords": {
                            "latitude": 33.572154,
                            "longitude": -112.090132
                        },
                        "cost_electricity": 0.1,
                        "cost_gas": 10.53
                    },

                    {
                        "id": 29,
                        "country_id": 1,
                        "country": "United States",
                        "country_abbrev": "USA",
                        "state_id": 5,
                        "state_abbrev": "CA",
                        "state_name": "California",
                        "city_state": "Los Angeles, CA",
                        "city": "Los Angeles",
                        "coords": {
                            "latitude": 34.019394,
                            "longitude": -118.410825
                        },
                        "cost_electricity": 0.14,
                        "cost_gas": 7.98
                    },

                    {
                        "id": 30,
                        "country_id": 1,
                        "country": "United States",
                        "country_abbrev": "USA",
                        "state_id": 5,
                        "state_abbrev": "CA",
                        "state_name": "California",
                        "city_state": "Sacramento, CA",
                        "city": "Sacramento",
                        "coords": {
                            "latitude": 38.566592,
                            "longitude": -121.468632
                        },
                        "cost_electricity": 0.14,
                        "cost_gas": 7.98
                    },

                    {
                        "id": 32,
                        "country_id": 1,
                        "country": "United States",
                        "country_abbrev": "USA",
                        "state_id": 5,
                        "state_abbrev": "CA",
                        "state_name": "California",
                        "city_state": "San Francisco, CA",
                        "city": "San Francisco",
                        "coords": {
                            "latitude": 37.727239,
                            "longitude": -123.032229
                        },
                        "cost_electricity": 0.14,
                        "cost_gas": 7.98
                    },

                    {
                        "id": 34,
                        "country_id": 1,
                        "country": "United States",
                        "country_abbrev": "USA",
                        "state_id": 6,
                        "state_abbrev": "CO",
                        "state_name": "Colorado",
                        "city_state": "Boulder, CO",
                        "city": "Boulder",
                        "coords": {
                            "latitude": 40.027443,
                            "longitude": -105.25174
                        },
                        "cost_electricity": 0.09,
                        "cost_gas": 8.15
                    },

                    {
                        "id": 46,
                        "country_id": 1,
                        "country": "United States",
                        "country_abbrev": "USA",
                        "state_id": 9,
                        "state_abbrev": "FL",
                        "state_name": "Florida",
                        "city_state": "Miami, FL",
                        "city": "Miami",
                        "coords": {
                            "latitude": 25.775163,
                            "longitude": -80.208615
                        },
                        "cost_electricity": 0.09,
                        "cost_gas": 10.74
                    },

                    {
                        "id": 48,
                        "country_id": 1,
                        "country": "United States",
                        "country_abbrev": "USA",
                        "state_id": 9,
                        "state_abbrev": "FL",
                        "state_name": "Florida",
                        "city_state": "Tampa, FL",
                        "city": "Tampa",
                        "coords": {
                            "latitude": 27.970086,
                            "longitude": -82.479673
                        },
                        "cost_electricity": 0.09,
                        "cost_gas": 10.74
                    },

                    {
                        "id": 51,
                        "country_id": 1,
                        "country": "United States",
                        "country_abbrev": "USA",
                        "state_id": 10,
                        "state_abbrev": "GA",
                        "state_name": "Georgia",
                        "city_state": "Atlanta, GA",
                        "city": "Atlanta",
                        "coords": {
                            "latitude": 33.762909,
                            "longitude": -84.422675
                        },
                        "cost_electricity": 0.1,
                        "cost_gas": 8.49
                    },

                    {
                        "id": 60,
                        "country_id": 1,
                        "country": "United States",
                        "country_abbrev": "USA",
                        "state_id": 13,
                        "state_abbrev": "ID",
                        "state_name": "Idaho",
                        "city_state": "Boise, ID",
                        "city": "Boise",
                        "coords": {
                            "latitude": 43.599335,
                            "longitude": -116.229817
                        },
                        "cost_electricity": 0.08,
                        "cost_gas": 7.61
                    },

                    {
                        "id": 62,
                        "country_id": 1,
                        "country": "United States",
                        "country_abbrev": "USA",
                        "state_id": 14,
                        "state_abbrev": "IL",
                        "state_name": "Illinois",
                        "city_state": "Chicago, IL",
                        "city": "Chicago",
                        "coords": {
                            "latitude": 41.837551,
                            "longitude": -87.681844
                        },
                        "cost_electricity": 0.09,
                        "cost_gas": 7.26
                    },

                    {
                        "id": 69,
                        "country_id": 1,
                        "country": "United States",
                        "country_abbrev": "USA",
                        "state_id": 15,
                        "state_abbrev": "IN",
                        "state_name": "Indiana",
                        "city_state": "Indianapolis, IN",
                        "city": "Indianapolis",
                        "coords": {
                            "latitude": 39.776664,
                            "longitude": -86.145935
                        },
                        "cost_electricity": 0.1,
                        "cost_gas": 7.58
                    },

                    {
                        "id": 78,
                        "country_id": 1,
                        "country": "United States",
                        "country_abbrev": "USA",
                        "state_id": 15,
                        "state_abbrev": "KS",
                        "state_name": "Kansas",
                        "city_state": "Wichita, KS",
                        "city": "Wichita",
                        "coords": {
                            "latitude": 37.690694,
                            "longitude": -97.342678
                        },
                        "cost_electricity": 0.1,
                        "cost_gas": 8.83
                    },

                    {
                        "id": 81,
                        "country_id": 1,
                        "country": "United States",
                        "country_abbrev": "USA",
                        "state_id": 16,
                        "state_abbrev": "KY",
                        "state_name": "Kentucky",
                        "city_state": "Louisville, KY",
                        "city": "Louisville",
                        "coords": {
                            "latitude": 38.225371,
                            "longitude": -85.741613
                        },
                        "cost_electricity": 0.09,
                        "cost_gas": 8.8
                    },

                    {
                        "id": 85,
                        "country_id": 1,
                        "country": "United States",
                        "country_abbrev": "USA",
                        "state_id": 17,
                        "state_abbrev": "LA",
                        "state_name": "Louisiana",
                        "city_state": "New Orleans, LA",
                        "city": "New Orleans",
                        "coords": {
                            "latitude": 30.05342,
                            "longitude": -89.934502
                        },
                        "cost_electricity": 0.09,
                        "cost_gas": 7.93
                    },

                    {
                        "id": 88,
                        "country_id": 1,
                        "country": "United States",
                        "country_abbrev": "USA",
                        "state_id": 18,
                        "state_abbrev": "ME",
                        "state_name": "Maine",
                        "city_state": "Portland, ME",
                        "city": "Portland",
                        "coords": {
                            "latitude": 43.633192,
                            "longitude": -70.185644
                        },
                        "cost_electricity": 0.12,
                        "cost_gas": 14.4
                    },

                    {
                        "id": 89,
                        "country_id": 1,
                        "country": "United States",
                        "country_abbrev": "USA",
                        "state_id": 19,
                        "state_abbrev": "MD",
                        "state_name": "Maryland",
                        "city_state": "Baltimore, MD",
                        "city": "Baltimore",
                        "coords": {
                            "latitude": 39.300032,
                            "longitude": -76.610476
                        },
                        "cost_electricity": 0.11,
                        "cost_gas": 10
                    },

                    {
                        "id": 90,
                        "country_id": 1,
                        "country": "United States",
                        "country_abbrev": "USA",
                        "state_id": 20,
                        "state_abbrev": "MA",
                        "state_name": "Massachusetts",
                        "city_state": "Boston, MA",
                        "city": "Boston",
                        "coords": {
                            "latitude": 42.33196,
                            "longitude": -71.020173
                        },
                        "cost_electricity": 0.15,
                        "cost_gas": 12.48
                    },

                    {
                        "id": 93,
                        "country_id": 1,
                        "country": "United States",
                        "country_abbrev": "USA",
                        "state_id": 21,
                        "state_abbrev": "MI",
                        "state_name": "Michigan",
                        "city_state": "Detroit, MI",
                        "city": "Detroit",
                        "coords": {
                            "latitude": 42.383037,
                            "longitude": -83.102237
                        },
                        "cost_electricity": 0.1,
                        "cost_gas": 7.49
                    },

                    {
                        "id": 100,
                        "country_id": 1,
                        "country": "United States",
                        "country_abbrev": "USA",
                        "state_id": 21,
                        "state_abbrev": "MI",
                        "state_name": "Michigan",
                        "city_state": "Traverse City, MI",
                        "city": "Traverse City",
                        "coords": {
                            "latitude": 44.754309,
                            "longitude": -85.603011
                        },
                        "cost_electricity": 0.1,
                        "cost_gas": 7.49
                    },

                    {
                        "id": 101,
                        "country_id": 1,
                        "country": "United States",
                        "country_abbrev": "USA",
                        "state_id": 22,
                        "state_abbrev": "MN",
                        "state_name": "Minnesota",
                        "city_state": "Duluth, MN",
                        "city": "Duluth",
                        "coords": {
                            "latitude": 46.783229,
                            "longitude": -92.11871
                        },
                        "cost_electricity": 0.1,
                        "cost_gas": 7.3
                    },

                    {
                        "id": 103,
                        "country_id": 1,
                        "country": "United States",
                        "country_abbrev": "USA",
                        "state_id": 22,
                        "state_abbrev": "MN",
                        "state_name": "Minnesota",
                        "city_state": "Minneapolis, MN",
                        "city": "Minneapolis",
                        "coords": {
                            "latitude": 44.963324,
                            "longitude": -93.26832
                        },
                        "cost_electricity": 0.1,
                        "cost_gas": 7.3
                    },

                    {
                        "id": 110,
                        "country_id": 1,
                        "country": "United States",
                        "country_abbrev": "USA",
                        "state_id": 24,
                        "state_abbrev": "MO",
                        "state_name": "Missouri",
                        "city_state": "St. Louis, MO",
                        "city": "St. Louis",
                        "coords": {
                            "latitude": 38.635699,
                            "longitude": -90.244582
                        },
                        "cost_electricity": 0.08,
                        "cost_gas": 9.1
                    },

                    {
                        "id": 116,
                        "country_id": 1,
                        "country": "United States",
                        "country_abbrev": "USA",
                        "state_id": 25,
                        "state_abbrev": "MT",
                        "state_name": "Montana",
                        "city_state": "Helena, MT",
                        "city": "Helena",
                        "coords": {
                            "latitude": 46.595465,
                            "longitude": -112.018746
                        },
                        "cost_electricity": 0.1,
                        "cost_gas": 7.82
                    },

                    {
                        "id": 124,
                        "country_id": 1,
                        "country": "United States",
                        "country_abbrev": "USA",
                        "state_id": 26,
                        "state_abbrev": "NE",
                        "state_name": "Nebraska",
                        "city_state": "Omaha, NE",
                        "city": "Omaha",
                        "coords": {
                            "latitude": 41.263765,
                            "longitude": -96.041638
                        },
                        "cost_electricity": 0.09,
                        "cost_gas": 6.54
                    },

                    {
                        "id": 128,
                        "country_id": 1,
                        "country": "United States",
                        "country_abbrev": "USA",
                        "state_id": 27,
                        "state_abbrev": "NV",
                        "state_name": "Nevada",
                        "city_state": "Las Vegas, NV",
                        "city": "Las Vegas",
                        "coords": {
                            "latitude": 36.229214,
                            "longitude": -115.260082
                        },
                        "cost_electricity": 0.08,
                        "cost_gas": 8.66
                    },

                    {
                        "id": 129,
                        "country_id": 1,
                        "country": "United States",
                        "country_abbrev": "USA",
                        "state_id": 27,
                        "state_abbrev": "NV",
                        "state_name": "Nevada",
                        "city_state": "Reno, NV",
                        "city": "Reno",
                        "coords": {
                            "latitude": 39.549098,
                            "longitude": -119.849905
                        },
                        "cost_electricity": 0.08,
                        "cost_gas": 8.66
                    },

                    {
                        "id": 135,
                        "country_id": 1,
                        "country": "United States",
                        "country_abbrev": "USA",
                        "state_id": 30,
                        "state_abbrev": "NM",
                        "state_name": "New Mexico",
                        "city_state": "Albuquerque, NM",
                        "city": "Albuquerque",
                        "coords": {
                            "latitude": 35.105552,
                            "longitude": -106.647388
                        },
                        "cost_electricity": 0.09,
                        "cost_gas": 7.87
                    },

                    {
                        "id": 141,
                        "country_id": 1,
                        "country": "United States",
                        "country_abbrev": "USA",
                        "state_id": 31,
                        "state_abbrev": "NY",
                        "state_name": "New York",
                        "city_state": "New York City, NY",
                        "city": "New York City",
                        "coords": {
                            "latitude": 40.664274,
                            "longitude": -73.9385
                        },
                        "cost_electricity": 0.13,
                        "cost_gas": 6.89
                    },

                    {
                        "id": 143,
                        "country_id": 1,
                        "country": "United States",
                        "country_abbrev": "USA",
                        "state_id": 31,
                        "state_abbrev": "NY",
                        "state_name": "New York",
                        "city_state": "Syracuse, NY",
                        "city": "Syracuse",
                        "coords": {
                            "latitude": 43.040998,
                            "longitude": -76.143554
                        },
                        "cost_electricity": 0.13,
                        "cost_gas": 6.89
                    },

                    {
                        "id": 145,
                        "country_id": 1,
                        "country": "United States",
                        "country_abbrev": "USA",
                        "state_id": 32,
                        "state_abbrev": "NC",
                        "state_name": "North Carolina",
                        "city_state": "Charlotte, NC",
                        "city": "Charlotte",
                        "coords": {
                            "latitude": 35.208707,
                            "longitude": -80.830739
                        },
                        "cost_electricity": 0.09,
                        "cost_gas": 9.12
                    },

                    {
                        "id": 150,
                        "country_id": 1,
                        "country": "United States",
                        "country_abbrev": "USA",
                        "state_id": 33,
                        "state_abbrev": "ND",
                        "state_name": "North Dakota",
                        "city_state": "Bismarck, ND",
                        "city": "Bismarck",
                        "coords": {
                            "latitude": 46.812084,
                            "longitude": -100.770099
                        },
                        "cost_electricity": 0.09,
                        "cost_gas": 6.7
                    },

                    {
                        "id": 154,
                        "country_id": 1,
                        "country": "United States",
                        "country_abbrev": "USA",
                        "state_id": 34,
                        "state_abbrev": "OH",
                        "state_name": "Ohio",
                        "city_state": "Cleveland, OH",
                        "city": "Cleveland",
                        "coords": {
                            "latitude": 41.478462,
                            "longitude": -81.679435
                        },
                        "cost_electricity": 0.1,
                        "cost_gas": 6.62
                    },

                    {
                        "id": 161,
                        "country_id": 1,
                        "country": "United States",
                        "country_abbrev": "USA",
                        "state_id": 35,
                        "state_abbrev": "OK",
                        "state_name": "Oklahoma",
                        "city_state": "Tulsa, OK",
                        "city": "Tulsa",
                        "coords": {
                            "latitude": 36.127949,
                            "longitude": -95.902316
                        },
                        "cost_electricity": 0.07,
                        "cost_gas": 8.22
                    },

                    {
                        "id": 168,
                        "country_id": 1,
                        "country": "United States",
                        "country_abbrev": "USA",
                        "state_id": 36,
                        "state_abbrev": "OR",
                        "state_name": "Oregon",
                        "city_state": "Portland, OR",
                        "city": "Portland",
                        "coords": {
                            "latitude": 45.536951,
                            "longitude": -122.649971
                        },
                        "cost_electricity": 0.09,
                        "cost_gas": 9.44
                    },

                    {
                        "id": 169,
                        "country_id": 1,
                        "country": "United States",
                        "country_abbrev": "USA",
                        "state_id": 36,
                        "state_abbrev": "OR",
                        "state_name": "Oregon",
                        "city_state": "Redmond, OR",
                        "city": "Redmond",
                        "coords": {
                            "latitude": 44.262753,
                            "longitude": -121.180137
                        },
                        "cost_electricity": 0.09,
                        "cost_gas": 9.44
                    },

                    {
                        "id": 175,
                        "country_id": 1,
                        "country": "United States",
                        "country_abbrev": "USA",
                        "state_id": 37,
                        "state_abbrev": "PA",
                        "state_name": "Pennsylvania",
                        "city_state": "Philadelphia, PA",
                        "city": "Philadelphia",
                        "coords": {
                            "latitude": 40.009376,
                            "longitude": -75.133346
                        },
                        "cost_electricity": 0.09,
                        "cost_gas": 10.13
                    },

                    {
                        "id": 176,
                        "country_id": 1,
                        "country": "United States",
                        "country_abbrev": "USA",
                        "state_id": 37,
                        "state_abbrev": "PA",
                        "state_name": "Pennsylvania",
                        "city_state": "Pittsburgh, PA",
                        "city": "Pittsburgh",
                        "coords": {
                            "latitude": 40.439753,
                            "longitude": -79.976592
                        },
                        "cost_electricity": 0.09,
                        "cost_gas": 10.13
                    },

                    {
                        "id": 180,
                        "country_id": 1,
                        "country": "United States",
                        "country_abbrev": "USA",
                        "state_id": 39,
                        "state_abbrev": "SC",
                        "state_name": "South Carolina",
                        "city_state": "Charleston, SC",
                        "city": "Charleston",
                        "coords": {
                            "latitude": 32.81793,
                            "longitude": -79.95896
                        },
                        "cost_electricity": 0.1,
                        "cost_gas": 8.37
                    },

                    {
                        "id": 185,
                        "country_id": 1,
                        "country": "United States",
                        "country_abbrev": "USA",
                        "state_id": 41,
                        "state_abbrev": "SD",
                        "state_name": "South Dakota",
                        "city_state": "Rapid City, SD",
                        "city": "Rapid City",
                        "coords": {
                            "latitude": 44.071071,
                            "longitude": -103.217924
                        },
                        "cost_electricity": 0.09,
                        "cost_gas": 6.11
                    },

                    {
                        "id": 191,
                        "country_id": 1,
                        "country": "United States",
                        "country_abbrev": "USA",
                        "state_id": 42,
                        "state_abbrev": "TN",
                        "state_name": "Tennessee",
                        "city_state": "Nashville, TN",
                        "city": "Nashville",
                        "coords": {
                            "latitude": 36.1718,
                            "longitude": -86.785002
                        },
                        "cost_electricity": 0.1,
                        "cost_gas": 8.46
                    },

                    {
                        "id": 198,
                        "country_id": 1,
                        "country": "United States",
                        "country_abbrev": "USA",
                        "state_id": 43,
                        "state_abbrev": "TX",
                        "state_name": "Texas",
                        "city_state": "Fort Worth, TX",
                        "city": "Fort Worth",
                        "coords": {
                            "latitude": 32.781518,
                            "longitude": -97.346746
                        },
                        "cost_electricity": 0.08,
                        "cost_gas": 8.26
                    },

                    {
                        "id": 204,
                        "country_id": 1,
                        "country": "United States",
                        "country_abbrev": "USA",
                        "state_id": 43,
                        "state_abbrev": "TX",
                        "state_name": "Texas",
                        "city_state": "San Antonio, TX",
                        "city": "San Antonio",
                        "coords": {
                            "latitude": 29.472403,
                            "longitude": -98.525142
                        },
                        "cost_electricity": 0.08,
                        "cost_gas": 8.26
                    },

                    {
                        "id": 209,
                        "country_id": 1,
                        "country": "United States",
                        "country_abbrev": "USA",
                        "state_id": 44,
                        "state_abbrev": "UT",
                        "state_name": "Utah",
                        "city_state": "Salt Lake City, UT",
                        "city": "Salt Lake City",
                        "coords": {
                            "latitude": 40.778996,
                            "longitude": -111.93263
                        },
                        "cost_electricity": 0.09,
                        "cost_gas": 7.97
                    },

                    {
                        "id": 210,
                        "country_id": 1,
                        "country": "United States",
                        "country_abbrev": "USA",
                        "state_id": 45,
                        "state_abbrev": "VT",
                        "state_name": "Vermont",
                        "city_state": "Burlington, VT",
                        "city": "Burlington",
                        "coords": {
                            "latitude": 44.491927,
                            "longitude": -73.238882
                        },
                        "cost_electricity": 0.14,
                        "cost_gas": 9.13
                    },

                    {
                        "id": 213,
                        "country_id": 1,
                        "country": "United States",
                        "country_abbrev": "USA",
                        "state_id": 46,
                        "state_abbrev": "VA",
                        "state_name": "Virginia",
                        "city_state": "Richmond, VA",
                        "city": "Richmond",
                        "coords": {
                            "latitude": 37.531399,
                            "longitude": -77.476009
                        },
                        "cost_electricity": 0.08,
                        "cost_gas": 8.11
                    },

                    {
                        "id": 218,
                        "country_id": 1,
                        "country": "United States",
                        "country_abbrev": "USA",
                        "state_id": 47,
                        "state_abbrev": "WA",
                        "state_name": "Washington",
                        "city_state": "Seattle, WA",
                        "city": "Seattle",
                        "coords": {
                            "latitude": 47.620499,
                            "longitude": -122.350876
                        },
                        "cost_electricity": 0.08,
                        "cost_gas": 9.14
                    },

                    {
                        "id": 219,
                        "country_id": 1,
                        "country": "United States",
                        "country_abbrev": "USA",
                        "state_id": 47,
                        "state_abbrev": "WA",
                        "state_name": "Washington",
                        "city_state": "Spokane, WA",
                        "city": "Spokane",
                        "coords": {
                            "latitude": 47.666935,
                            "longitude": -117.433322
                        },
                        "cost_electricity": 0.08,
                        "cost_gas": 9.14
                    },

                    {
                        "id": 223,
                        "country_id": 1,
                        "country": "United States",
                        "country_abbrev": "USA",
                        "state_id": 48,
                        "state_abbrev": "WV",
                        "state_name": "West Virginia",
                        "city_state": "Huntington, WV",
                        "city": "Huntington",
                        "coords": {
                            "latitude": 38.410651,
                            "longitude": -82.434689
                        },
                        "cost_electricity": 0.09,
                        "cost_gas": 9.15
                    },

                    {
                        "id": 227,
                        "country_id": 1,
                        "country": "United States",
                        "country_abbrev": "USA",
                        "state_id": 49,
                        "state_abbrev": "WI",
                        "state_name": "Wisconsin",
                        "city_state": "Madison, WI",
                        "city": "Madison",
                        "coords": {
                            "latitude": 43.087815,
                            "longitude": -89.429856
                        },
                        "cost_electricity": 0.11,
                        "cost_gas": 8.74
                    },

                    {
                        "id": 230,
                        "country_id": 1,
                        "country": "United States",
                        "country_abbrev": "USA",
                        "state_id": 50,
                        "state_abbrev": "WY",
                        "state_name": "Wyoming",
                        "city_state": "Cheyenne, WY",
                        "city": "Cheyenne",
                        "coords": {
                            "latitude": 41.143657,
                            "longitude": -104.792833
                        },
                        "cost_electricity": 0.09,
                        "cost_gas": 7.69
                    },

                    {
                        "id": 235,
                        "country_id": 2,
                        "country": "Canada",
                        "country_abbrev": "CAN",
                        "state_id": 53,
                        "state_abbrev": "BC",
                        "state_name": "British Columbia",
                        "city_state": "Vancouver, BC",
                        "city": "Vancouver",
                        "coords": {
                            "latitude": 49.25,
                            "longitude": -123.1
                        },
                        "cost_electricity": 0.09,
                        "cost_gas": 7.69
                    },

                    {
                        "id": 236,
                        "country_id": 2,
                        "country": "Canada",
                        "country_abbrev": "CAN",
                        "state_id": 54,
                        "state_abbrev": "MB",
                        "state_name": "Manitoba",
                        "city_state": "Winnipeg, MB",
                        "city": "Winnipeg",
                        "coords": {
                            "latitude": 49.9,
                            "longitude": -97.13555556
                        },
                        "cost_electricity": 0.07,
                        "cost_gas": 6.51
                    },

                    {
                        "id": 237,
                        "country_id": 2,
                        "country": "Canada",
                        "country_abbrev": "CAN",
                        "state_id": 55,
                        "state_abbrev": "NS",
                        "state_name": "Nova Scotia",
                        "city_state": "Halifax, NS",
                        "city": "Halifax",
                        "coords": {
                            "latitude": 44.36,
                            "longitude": -63.32
                        },
                        "cost_electricity": 0.07,
                        "cost_gas": 14.45
                    },

                    {
                        "id": 238,
                        "country_id": 2,
                        "country": "Canada",
                        "country_abbrev": "CAN",
                        "state_id": 56,
                        "state_abbrev": "ON",
                        "state_name": "Ontario",
                        "city_state": "Toronto, ON",
                        "city": "Toronto",
                        "coords": {
                            "latitude": 43.652612,
                            "longitude": -79.380833
                        },
                        "cost_electricity": 0.13,
                        "cost_gas": 6.38
                    },

                    {
                        "id": 240,
                        "country_id": 2,
                        "country": "Canada",
                        "country_abbrev": "CAN",
                        "state_id": 57,
                        "state_abbrev": "QC",
                        "state_name": "Quebec",
                        "city_state": "Montreal, QC",
                        "city": "Montreal",
                        "coords": {
                            "latitude": 45.516274,
                            "longitude": -73.569235
                        },
                        "cost_electricity": 0.09,
                        "cost_gas": 11.92
                    },


                    /*{
                        "id": 242,
                        "country_id": 3,
                        "country": "China",
                        "country_abbrev": "CHN",
                        "state_id": 58,
                        "state_abbrev": "BJ",
                        "state_name": "Beijing",
                        "city_state": "Beijing",
                        "city": "Beijing",
                        "coords": {
                            "latitude": 39.913818,
                            "longitude": 116.363625
                        },
                        "cost_electricity": 0.78,
                        "cost_gas": 103.4
                    },

                    {
                        "id": 243,
                        "country_id": 3,
                        "country": "China",
                        "country_abbrev": "CHN",
                        "state_id": 59,
                        "state_abbrev": "SH",
                        "state_name": "Shanghai",
                        "city_state": "Shanghai",
                        "city": "Shanghai",
                        "coords": {
                            "latitude": 31.234614,
                            "longitude": 121.467104
                        },
                        "cost_electricity": 0.78,
                        "cost_gas": 103.4
                    },

                    {
                        "id": 244,
                        "country_id": 3,
                        "country": "China",
                        "country_abbrev": "CHN",
                        "state_id": 60,
                        "state_abbrev": "GD",
                        "state_name": "Guangdong",
                        "city_state": "Guangzhou",
                        "city": "Guangzhou",
                        "coords": {
                            "latitude": 23.14303,
                            "longitude": 113.259391
                        },
                        "cost_electricity": 0.78,
                        "cost_gas": 103.4
                    },

                    {
                        "id": 245,
                        "country_id": 3,
                        "country": "China",
                        "country_abbrev": "CHN",
                        "state_id": 61,
                        "state_abbrev": "HL",
                        "state_name": "Heilongjiang",
                        "city_state": "Harbin",
                        "city": "Harbin",
                        "coords": {
                            "latitude": 45.809339,
                            "longitude": 126.543524
                        },
                        "cost_electricity": 0.78,
                        "cost_gas": 103.4
                    },

                    {
                        "id": 246,
                        "country_id": 3,
                        "country": "China",
                        "country_abbrev": "CHN",
                        "state_id": 62,
                        "state_abbrev": "JL",
                        "state_name": "Jilin",
                        "city_state": "Changchun",
                        "city": "Changchun",
                        "coords": {
                            "latitude": 43.817727,
                            "longitude": 125.325875
                        },
                        "cost_electricity": 0.78,
                        "cost_gas": 103.4
                    }, */

                ]

        }

        function htmlDecode(input) {
            var e = document.createElement('div');
            e.innerHTML = input;
            return e.childNodes[0].nodeValue;
        }

        function initUnits() {
            vm.$storage.areaUnits = [
                {
                    id: 1, name: htmlDecode("ft&sup2;"), val: "SQFT"
                },
                {
                    id: 2, name: htmlDecode("m&sup2;"), val: "M2"
                }
            ];

            vm.$storage.leakageUnits = [
                {
                    id: 1, name: htmlDecode("L/s/m&sup2;"), val: "LPERSECM2"
                },
                {
                    id: 2, name: htmlDecode("CFM/ft&sup2;"), val: "CFMSQFT"
                },
                {
                    id: 3, name: "ACH", val: "ACH50"
                }
            ];

            vm.$storage.gasCostUnitss = [
                {
                    id: 1, name: htmlDecode("1000 ft&sup3;"), val: "FT3", energy: htmlDecode("ft&sup3;")
                },
                {
                    id: 2, name: htmlDecode("m&sup3;"), val: "M3"
                }
            ];
            vm.$storage.moistureTransferUnits = [
                {
                    id: 1, name: htmlDecode("L/m&sup2;/year"), val: "M2"
                },
                {
                    id: 2, name: htmlDecode("gal/ft&sup2;/year"), val: "SQFT"
                }
            ];
            vm.$storage.elaUnits = [
                {
                    id: 1, name: htmlDecode("m&sup2;"), val: "M2"
                },
                {
                    id: 2, name: htmlDecode("ft&sup2;"), val: "SQFT"
                }

            ];

            vm.$storage.moistureTransferELA = [
                {
                    id: 1, name: htmlDecode("L/cm&sup2;/year"), val: "M2"
                },
                {
                    id: 2, name: htmlDecode("gal/in&sup2;/year"), val: "SQFT"
                }
            ];

            vm.$storage.moistureTransferTotal = [
                {
                    id: 1, name: ("L/year"), val: "M2"
                },
                {
                    id: 2, name:("gal/year"), val: "SQFT"
                }
            ];
            vm.$storage.elaAreas  = [
                { id: 1, fa: 2294, ea: 3471 }, // standalone retail
                { id: 2, fa: 3131, ea: 2326 }, // mid rise apt
                { id: 3, fa: 4980, ea: 3640 }, // office medium
                { id: 4, fa: 7837, ea: 4639 }, // hi rise apt
                { id: 5, fa: 22428, ea: 9089 }, // hospital
                { id: 6, fa: 11346, ea: 6005 }, // large hotel
                { id: 7, fa: 19593, ea: 17871 }, // secondary school
                { id: 8, fa: 22500, ea: 3274 }, // strip mall
                {id: 9, fa: 73958, ea: 9383}, //Primary School
                {id: 10, fa: 221, ea: 441} //resedential
            ]
        }
    }
})();
