(function() {
    'use strict';

    angular
        .module('infiltrationApp')
        .controller('EnergyCalcDetailController', EnergyCalcDetailController);

    EnergyCalcDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'EnergyCalc', 'MapLocation', 'BuildingType'];

    function EnergyCalcDetailController($scope, $rootScope, $stateParams, previousState, entity, EnergyCalc, MapLocation, BuildingType) {
        var vm = this;

        vm.energyCalc = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('infiltrationApp:energyCalcUpdate', function(event, result) {
            vm.energyCalc = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
