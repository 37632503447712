/**
 * Created by g9a on 9/19/16.
 */
(function () {
    'use strict';

    angular
        .module('infiltrationApp')
        .controller('InfiltrationCalculationController', InfiltrationCalculationController);

    InfiltrationCalculationController.$inject = ['$scope', '$localStorage', 'Principal', '$state', '$sce', 'InfiltrationEntry', 'InfiltrationResult', 'EnergyCalcQuery', 'EnergyCalculation', 'BuildingType', 'UnitConversion', 'MoistureTransferQuery', 'MoistureTransferCalculation'];

    function InfiltrationCalculationController ($scope, $localStorage, Principal, $state, $sce, InfiltrationEntry, InfiltrationResult, EnergyCalcQuery, EnergyCalculation, BuildingType, UnitConversion, MoistureTransferQuery, MoistureTransferCalculation) {
        var vm = this;
        var locationString = '';
        $scope.infiltrationEntry = $localStorage.infiltrationEntry;
        $scope.infiltrationResult = $localStorage.infiltrationResult;
        $scope.leakageUnitDisplayValue = _.find($localStorage.leakageUnits, { 'val': $scope.infiltrationEntry.leakageUnit}).name;
        $scope.areaUnitDisplayValue = _.find($localStorage.areaUnits, { 'val': $scope.infiltrationEntry.areaUnit}).name;
        $scope.gasCostUnitsDisplayValue = _.find($localStorage.gasCostUnitss, {'val': $scope.infiltrationEntry.gasCostUnits}).name;
        $scope.mtUnitsDisplayValue = _.find($localStorage.moistureTransferUnits, { 'val': $scope.infiltrationEntry.areaUnit}).name;
        $scope.elaUnitsDisplayValue = _.find($localStorage.elaUnits, { 'val': $scope.infiltrationEntry.areaUnit}).name;
        $scope.moistureELADisplayValue = _.find($localStorage.moistureTransferELA, { 'val': $scope.infiltrationEntry.areaUnit}).name;
        $scope.moistureTransferTotalDisplay = _.find($localStorage.moistureTransferTotal, { 'val': $scope.infiltrationEntry.areaUnit}).name;
        $scope.gasSavingsUnitsDisplayValue = "kBtu";
        $scope.gasSavingsDisplayValue = 0.0;
        $scope.gasCostSavingsDisplayValue = 0.0;
        $scope.electricCostSavingsDisplayValue = 0.0;
        $scope.electricSavingsDisplayValue = 0.0;
        $scope.totalSavingsDisplayValue = 0.0;
        $scope.totalCostSavingsDisplayValue = 0.0;
        $scope.bLeakFt = 0.0;
        $scope.rLeakFt = 0.0;

        switch ($scope.infiltrationEntry.mapLocation.country_abbrev) {
            case 'USA':
                $scope.currencyDisplayValue = "$ ";
                break;
            case 'CAN':
                $scope.currencyDisplayValue = "C$ ";
                break;
            case 'CHN':
                $scope.currencyDisplayValue = "¥ ";
                break;
        }


        var map_loc_id = $scope.infiltrationEntry.mapLocation.id;
        $scope.locationString = $scope.infiltrationEntry.mapLocation.city + ' ' + $scope.infiltrationEntry.mapLocation.state_abbrev + ' ' + $scope.infiltrationEntry.mapLocation.country_abbrev;
        var bldg_type_id = $scope.infiltrationEntry.buildingType.id;
        var buildingType = BuildingType.get({id: bldg_type_id});
        var elaArea = $localStorage.elaAreas.filter(function(e) {
            return e.id == bldg_type_id;
        });
        console.log('ela area ' + elaArea.id);

        $scope.fa = elaArea[0].fa; // meters^2
        $scope.ea = elaArea[0].ea; // meters^2
        $scope.uifa = 0.0; // meters^2
        var EnergyCalc = EnergyCalcQuery.get({map_location_id:map_loc_id, building_type_id:bldg_type_id}, function() {
            var x = EnergyCalc.xElectric;
            var x2 = EnergyCalc.x2Electric;
            var g = EnergyCalc.xGas;
            var g2 = EnergyCalc.x2Gas;
            $scope.bLeak = 0;
            $scope.rLeak = 0;
            $scope.actualAreaFt = 0;
            var electricCost = $scope.infiltrationEntry.costElectricity;
            var costGas = 0;

            if ($scope.infiltrationEntry.leakageUnit === 'CFMSQFT') {
                $scope.bLeak = parseFloat(UnitConversion.toLeakageMeters($scope.infiltrationEntry.basecaseLeakage));
                $scope.rLeak = parseFloat(UnitConversion.toLeakageMeters($scope.infiltrationEntry.retrofittedLeakage));
            }
            else {
                parseFloat($scope.bLeak = $scope.infiltrationEntry.basecaseLeakage);
                parseFloat($scope.rLeak = $scope.infiltrationEntry.retrofittedLeakage);
            }

         
            if ($scope.infiltrationEntry.areaUnit === 'M2') {
                $scope.uifa = $scope.infiltrationEntry.area;
                $scope.actualAreaFt = parseFloat(UnitConversion.toSquareFeet($scope.infiltrationEntry.area));
            } else {
                $scope.actualAreaFt = $scope.infiltrationEntry.area;
                $scope.uifa = parseFloat(UnitConversion.toMetersSquared($scope.infiltrationEntry.area));
            }
            if ($scope.infiltrationEntry.gasCostUnits === 'M3') {
                costGas = parseFloat(UnitConversion.toFeetCubed($scope.infiltrationEntry.costGas));
            } else {
                costGas = $scope.infiltrationEntry.costGas;
            }
            $scope.baseELA = (0.57719 * $scope.ea * $scope.uifa/$scope.fa * $scope.bLeak)/10000.0;
            $scope.retroELA = (0.57719 * $scope.ea * $scope.uifa/$scope.fa * $scope.rLeak)/10000.0;
            if ($scope.infiltrationEntry.areaUnit === 'SQFT') {
                $scope.baseELA = $scope. baseELA * 10.7639;
                $scope.retroELA = $scope.retroELA * 10.7639;
            }

            $scope.infiltrationResult.electricSavings = EnergyCalculation.calculateEnergySavings(x, x2, $scope.bLeak, $scope.rLeak, $scope.actualAreaFt, buildingType.floorArea);
            $scope.infiltrationResult.gasSavings = EnergyCalculation.calculateEnergySavings(g, g2, $scope.bLeak, $scope.rLeak, $scope.actualAreaFt, buildingType.floorArea);
            $scope.infiltrationResult.electricCostSavings = EnergyCalculation.costSavings($scope.infiltrationResult.electricSavings, electricCost);
            // gasCostSavings in cubic feet


            $scope.electricSavingsDisplayValue = $scope.infiltrationResult.electricSavings;
            $scope.electricCostSavingsDisplayValue = $scope.infiltrationResult.electricCostSavings;

            switch ($scope.infiltrationEntry.gasCostUnits) {
                case 'M3':
                    $scope.gasCostSavingsDisplayValue = EnergyCalculation.costSavingsGas($scope.infiltrationResult.gasSavings, costGas);
                    $scope.gasSavingsDisplayValue = parseFloat(UnitConversion.kbtuToMeterCubed($scope.infiltrationResult.gasSavings));
                    $scope.gasCostUnitsDisplayValue =  $localStorage.gasCostUnitss[1].name;
                    $scope.gasSavingsUnitsDisplayValue = $localStorage.gasCostUnitss[1].name;
                    break;
                default:
                    $scope.gasSavingsDisplayValue = parseFloat(UnitConversion.kbtuToFeetCubed($scope.infiltrationResult.gasSavings));
                    $scope.gasCostSavingsDisplayValue = EnergyCalculation.costSavingsGasFt($scope.gasSavingsDisplayValue, costGas);
                    //$scope.gasSavingsDisplayValue = $scope.infiltrationResult.gasSavings;
                    console.log("gas savings display value " + $scope.gasSavingsDisplayValue);
                    $scope.gasCostUnitsDisplayValue =  $localStorage.gasCostUnitss[0].name;
                    $scope.gasSavingsUnitsDisplayValue =  $localStorage.gasCostUnitss[0].energy;
                    break;
            }

            $scope.totalSavingsDisplayValue = $scope.gasSavingsDisplayValue + $scope.electricSavingsDisplayValue;
            $scope.totalCostSavingsDisplayValue = $scope.gasCostSavingsDisplayValue + $scope.electricCostSavingsDisplayValue;

            save();
            $scope.labels = ["Electricity", "Gas"];
            $scope.data = [$scope.electricCostSavingsDisplayValue, $scope.gasCostSavingsDisplayValue];

            $scope.gaslabels = ["Gas Saved"];
            $scope.gasdata = [$scope.gasSavingsDisplayValue];
            $scope.electricitylabels = ["Electricity Saved"];
            $scope.electricitydata = [$scope.electricSavingsDisplayValue];
            $scope.options = {
                title: {
                    display: true,
                    text: 'Cost Savings ' + $scope.currencyDisplayValue,
                    fontStyle: 'bold',
                    fontColor: '#000'
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true
                        }
                    }]
                }
            };
            $scope.colors = [
                {
                    backgroundColor: "rgba(218, 237, 246, 0.5)",
                    pointBackgroundColor: "rgba(218, 237, 246, 1)",
                    pointHoverBackgroundColor: "rgba(252, 255, 228, 1)",
                    borderColor: "rgba(159,204,0, 1)",
                    pointBorderColor: '#fff',
                    pointHoverBorderColor: "rgba(159,204,0, 1)"
                },
                {
                    backgroundColor: "rgba(153, 236, 233, 0.5)",
                    pointBackgroundColor: "rgba(153, 236, 233, 1)",
                    pointHoverBackgroundColor: "rgba(252, 248, 228, 1)",
                    borderColor: "rgba(159,204,0, 1)",
                    pointBorderColor: '#fff',
                    pointHoverBorderColor: "rgba(159,204,0, 1)"
                }
            ];

            var MoistureCalc = MoistureTransferQuery.get({map_location_id:map_loc_id, building_type_id: bldg_type_id}, function () {
                var ex = MoistureCalc.ex;
                var ex_2 = MoistureCalc.ex2;
                var econst = MoistureCalc.econstant;
                var bLeak2 = $scope.bLeak * $scope.bLeak;
                var rLeak2 = $scope.rLeak * $scope.rLeak;
                var elaBleak = .000057719 * $scope.bLeak * $scope.ea * 1550 ;
                var elaRleak = .000057719 * $scope.rLeak * $scope.ea * 1550 ;
                var baseresedentialELA = 0.01005 * $scope.bLeak * 1550;
                var retroresidentialELA = 0.01005 * $scope.rLeak * 1550;


                // $scope.uifa/$scope.fa * (ex_2 * bLeak2) + (ex * $scope.bLeakFt) + econst;
                // E76 *                   (F12 * E79^2)  +  (G12 * E79)           + H12)
                // Per Som email, 3/13/19
                //  Do not use the uifa/fa adjustments.

                // $scope.basecaseMoistureTransfer = $scope.uifa/$scope.fa * (ex_2 * bLeak2) + (ex * $scope.bLeak) + econst;
                // $scope.retrofittedMoistureTransfer = $scope.uifa/$scope.fa * (ex_2 * rLeak2) + (ex * $scope.rLeak) + econst;
                $scope.basecaseMoistureTransfer = (ex_2 * bLeak2) + (ex * $scope.bLeak) + econst;
                $scope.retrofittedMoistureTransfer =  (ex_2 * rLeak2) + (ex * $scope.rLeak) + econst;

//                $scope.basecaseMoistureTransfer = ex_2 * (bLeak2 + ex * $scope.bLeakFt + econst);
//                $scope.retrofittedMoistureTransfer = ex_2 * (rLeak2 + ex * $scope.rLeakFt + econst);

                if ($scope.infiltrationEntry.areaUnit === 'M2') {
                    $scope.basecaseMoistureTransfer = $scope.basecaseMoistureTransfer * 4.8824;
                    $scope.retrofittedMoistureTransfer = $scope.retrofittedMoistureTransfer * 4.8824;
                    $scope.basecaseGallonsPerYear = $scope.basecaseMoistureTransfer * $scope.ea  * 3.785412;
                    $scope.retrofittedGallonsPerYear = $scope.retrofittedMoistureTransfer * $scope.ea * 3.785412;
                    if($scope.infiltrationEntry.buildingType.shortName === "residential"){
                        $scope.basecaseperELA = $scope.basecaseGallonsPerYear/baseresedentialELA;
                        $scope.retrofittedPerELA = $scope.retrofittedGallonsPerYear/retroresidentialELA;
                    }
                    else{
                   
                    $scope.basecaseperELA = $scope.basecaseGallonsPerYear/elaBleak;
                    $scope.retrofittedPerELA = $scope.retrofittedGallonsPerYear/elaRleak;
                    }
                    
                    
                } else {
                    $scope.basecaseMoistureTransfer = $scope.basecaseMoistureTransfer/8.35;
                    $scope.retrofittedMoistureTransfer = $scope.retrofittedMoistureTransfer/8.35;
                    $scope.basecaseGallonsPerYear = $scope.basecaseMoistureTransfer * $scope.ea * 10.76391041671;
                    $scope.retrofittedGallonsPerYear = $scope.retrofittedMoistureTransfer * $scope.ea* 10.76391041671;
                    if($scope.infiltrationEntry.buildingType.shortName === "residential"){
                        $scope.basecaseperELA = $scope.basecaseGallonsPerYear/baseresedentialELA ;
                        $scope.retrofittedPerELA = $scope.retrofittedGallonsPerYear/retroresidentialELA;
                    }
                    else{
                   
                    $scope.basecaseperELA = $scope.basecaseGallonsPerYear/elaBleak;
                    $scope.retrofittedPerELA = $scope.retrofittedGallonsPerYear/elaRleak;
                    }
                } 

                $scope.reductionMoistureTransfer = $scope.basecaseMoistureTransfer - $scope.retrofittedMoistureTransfer;
                $scope.mtlabels = ["Base Case", "Retrofitted"];
                $scope.mtdata = [$scope.basecaseMoistureTransfer, $scope.retrofittedMoistureTransfer];
                $scope.mtoptions = {
                    title: {
                        display: true,
                        text: 'Moisture Transfer Reduction',
                        fontStyle: 'bold',
                        fontColor: '#000'
                    },
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true
                            },
                            scaleLabel: {
                                display: true,
                                labelString: $scope.mtUnitsDisplayValue
                            }
                        }]
                    }
                };
                $scope.mtcolors = [
                    {
                        backgroundColor: "rgba(252, 248, 228, 0.5)",
                        pointBackgroundColor: "rgba(252, 248, 228, 1)",
                        pointHoverBackgroundColor: "rgba(252, 248, 228, 1)",
                        borderColor: "rgba(159,204,0, 1)",
                        pointBorderColor: '#fff',
                        pointHoverBorderColor: "rgba(159,204,0, 1)"
                    },
                    {
                        backgroundColor: "rgba(248, 212, 127, 0.5)",
                        pointBackgroundColor: "rgba(248, 212, 127, 1)",
                        pointHoverBackgroundColor: "rgba(252, 255, 228, 1)",
                        borderColor: "rgba(159,204,0, 1)",
                        pointBorderColor: '#fff',
                        pointHoverBorderColor: "rgba(159,204,0, 1)"
                    }
                ];
            });
        });

        //vm.save = save;

        function save() {
            vm.isSavingResult = true;
            vm.isSaving = true;
            $scope.infiltrationEntry.infiltrationResults =  [];
            $scope.infiltrationEntry.infiltrationResults.push($scope.infiltrationResult);
            if ($scope.infiltrationEntry.id !== null) {
                InfiltrationEntry.update($scope.infiltrationEntry, onSaveSuccess, onSaveError);
            } else {
                InfiltrationEntry.save($scope.infiltrationEntry, onSaveSuccess, onSaveError);
            }

            //
            // if ($scope.infiltrationResult.id !== null) {
            //     InfiltrationResult.update($scope.infiltrationResult, onSaveSuccessResult, onSaveErrorResult);
            // } else {
            //     InfiltrationResult.save($scope.infiltrationResult, onSaveSuccessResult, onSaveErrorResult);
            // }

        }

        function onSaveSuccessResult (result) {
            $scope.$emit('infiltrationApp:infiltrationResultUpdate', result);
            $localStorage.infiltrationEntry = result;
            vm.isSavingResult = false;
        }

        function onSaveErrorResult () {
            vm.isSavingResult = false;
        }

        function onSaveSuccess (result) {
            $scope.$emit('infiltrationApp:infiltrationEntryUpdate', result);
            $localStorage.infiltrationEntry = result;
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }
        $scope.htmlAdText = function(text){ return $sce.trustAsHtml(text); };
    }



})();
