(function() {
    'use strict';

    angular
        .module('infiltrationApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('infiltration-result', {
            parent: 'entity',
            url: '/infiltration-result',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'InfiltrationResults'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/infiltration-result/infiltration-results.html',
                    controller: 'InfiltrationResultController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
            }
        })
        .state('infiltration-result-detail', {
            parent: 'entity',
            url: '/infiltration-result/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'InfiltrationResult'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/infiltration-result/infiltration-result-detail.html',
                    controller: 'InfiltrationResultDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'InfiltrationResult', function($stateParams, InfiltrationResult) {
                    return InfiltrationResult.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'infiltration-result',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('infiltration-result-detail.edit', {
            parent: 'infiltration-result-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/infiltration-result/infiltration-result-dialog.html',
                    controller: 'InfiltrationResultDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['InfiltrationResult', function(InfiltrationResult) {
                            return InfiltrationResult.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('infiltration-result.new', {
            parent: 'infiltration-result',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/infiltration-result/infiltration-result-dialog.html',
                    controller: 'InfiltrationResultDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                electricUse: null,
                                gasUse: null,
                                electricCostSavings: null,
                                gasCostSavings: null,
                                totalCostSavings: null,
                                totalSavings: null,
                                electricSavings: null,
                                gasSavings: null,
                                descriptionText: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('infiltration-result', null, { reload: 'infiltration-result' });
                }, function() {
                    $state.go('infiltration-result');
                });
            }]
        })
        .state('infiltration-result.edit', {
            parent: 'infiltration-result',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/infiltration-result/infiltration-result-dialog.html',
                    controller: 'InfiltrationResultDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['InfiltrationResult', function(InfiltrationResult) {
                            return InfiltrationResult.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('infiltration-result', null, { reload: 'infiltration-result' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('infiltration-result.delete', {
            parent: 'infiltration-result',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/infiltration-result/infiltration-result-delete-dialog.html',
                    controller: 'InfiltrationResultDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['InfiltrationResult', function(InfiltrationResult) {
                            return InfiltrationResult.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('infiltration-result', null, { reload: 'infiltration-result' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
