(function() {
    'use strict';

    angular
        .module('infiltrationApp')
        .factory('MoistureTransferCalculation', MoistureTransferCalculation);

    function MoistureTransferCalculation() {
        var service = {
        };
        return service;
    }
})();
