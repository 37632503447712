(function() {
    'use strict';

    angular
        .module('infiltrationApp')
        .controller('InfiltrationResultController', InfiltrationResultController);

    InfiltrationResultController.$inject = ['$scope', '$state', 'InfiltrationResult'];

    function InfiltrationResultController ($scope, $state, InfiltrationResult) {
        var vm = this;
        
        vm.infiltrationResults = [];

        loadAll();

        function loadAll() {
            InfiltrationResult.query(function(result) {
                vm.infiltrationResults = result;
            });
        }
    }
})();
