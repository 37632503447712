/**
 * Created by g9a on 9/21/16.
 */
(function() {
    'use strict';

    angular
        .module('infiltrationApp')
        .factory('EnergyCalculation', EnergyCalculation);

    function EnergyCalculation () {

        var service = {
            wholeHouseSavings : wholeHouseSavings,
            floorAreaSavings : floorAreaSavings,
            costSavings: costSavings,
            costSavingsGas: costSavingsGas,
            costSavingsGasFt: costSavingsGasFt,
            calculateEnergySavings: calculateEnergySavings,
            calculate: calculateCostSavings,
            calculateOnlyCostSavings: calculateOnlyCostSavings
        };

        return service;

        /*
         Whole House
         Electric savings = xElectric * (basecaseLeakage - retrofittedLeakage) + x2Electric * (basecaseLeakage^2 - retrofittedLeakage^2)
         Gas savings = xGas * (basecaseLeakage - retrofittedLeakage) + x2Gas * (basecaseLeakage^2 - retrofittedLeakage^2)

         User Specified Floor Area savings
         Electric savings = Whole House Electric * (sqft / BuildingType.floorArea);
         Gas savings = Whole House Gas * (sqft / BuildingType.floorArea);

         Dollar Savings
         Electric = Electric savings * costElectricity;
         Gas = Gas savings * costGas;

         */

        function floorAreaSavings (wholeHouse, actualSqft, buildingTypeFloorArea) {
            // Calculate floor area savings from whole house, adjust for squarefootage
            var output = wholeHouse * (actualSqft / buildingTypeFloorArea);
            return output;
        }

        function wholeHouseSavings (x, x2, basecaseLeakage, retrofittedLeakage) {
            // Calculate whole house savings
            // Math.pow did not give expected result, resorted to simply manually squaring
            var output = x * (basecaseLeakage - retrofittedLeakage) + x2 * ((basecaseLeakage * basecaseLeakage) - (retrofittedLeakage * retrofittedLeakage));
            return output;
        }

        function costSavings (savings, cost) {
            // To United States Dollar per thousand cubic feet
            var output = savings * cost;
            return output;
        }

        function costSavingsGas (savingskBtu, cost) {
            // To United States Dollar per thousand cubic feet
            var savingsft3 = savingskBtu/1.021;
            var output = (savingsft3/1000.0) * cost;
            return output;
        }

        function costSavingsGasFt(savingskBtu, cost) {
            var output = cost * savingskBtu/1000.0;
            return output;
        }

        function calculateEnergySavings (x, x2, basecaseLeakage, retrofittedLeakage, actualSqft, buildingTypeFloorArea) {
            // Perform all calculations
            var wholeHouse = wholeHouseSavings(x, x2, basecaseLeakage, retrofittedLeakage);
            var floorArea = floorAreaSavings(wholeHouse, actualSqft, buildingTypeFloorArea);

            return floorArea;
        }

        function calculateCostSavings (floorAreaSavings, cost) {
            return floorArea * cost;
        }

        function calculateOnlyCostSavings (x, x2, basecaseLeakage, retrofittedLeakage, actualSqft, buildingTypeFloorArea, cost) {
            // Perform all calculations
            var wholeHouse = wholeHouseSavings(x, x2, basecaseLeakage, retrofittedLeakage);
            var floorArea = floorAreaSavings(wholeHouse, actualSqft, buildingTypeFloorArea);
            var costSavings = floorArea * cost;
            return costSavings;
        }


    }
})();
