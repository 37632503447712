(function() {
    'use strict';

    angular
        .module('infiltrationApp')
        .controller('UsStateController', UsStateController);

    UsStateController.$inject = ['$scope', '$state', 'UsState'];

    function UsStateController ($scope, $state, UsState) {
        var vm = this;
        
        vm.usStates = [];

        loadAll();

        function loadAll() {
            UsState.query(function(result) {
                vm.usStates = result;
            });
        }
    }
})();
