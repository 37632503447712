(function() {
    'use strict';

    angular
        .module('infiltrationApp')
        .controller('MoistureTransferDialogController', MoistureTransferDialogController);

    MoistureTransferDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'MoistureTransfer', 'BuildingType', 'MapLocation'];

    function MoistureTransferDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, MoistureTransfer, BuildingType, MapLocation) {
        var vm = this;

        vm.moistureTransfer = entity;
        vm.clear = clear;
        vm.save = save;
        vm.buildingtypes = BuildingType.query();
        vm.maplocations = MapLocation.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.moistureTransfer.id !== null) {
                MoistureTransfer.update(vm.moistureTransfer, onSaveSuccess, onSaveError);
            } else {
                MoistureTransfer.save(vm.moistureTransfer, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('infiltrationApp:moistureTransferUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
