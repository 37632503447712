(function() {
    'use strict';

    angular
        .module('infiltrationApp')
        .controller('InfiltrationResultDetailController', InfiltrationResultDetailController);

    InfiltrationResultDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'InfiltrationResult', 'InfiltrationEntry'];

    function InfiltrationResultDetailController($scope, $rootScope, $stateParams, previousState, entity, InfiltrationResult, InfiltrationEntry) {
        var vm = this;

        vm.infiltrationResult = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('infiltrationApp:infiltrationResultUpdate', function(event, result) {
            vm.infiltrationResult = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
