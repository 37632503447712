(function() {
    'use strict';

    angular
        .module('infiltrationApp')
        .controller('InfiltrationResultDeleteController',InfiltrationResultDeleteController);

    InfiltrationResultDeleteController.$inject = ['$uibModalInstance', 'entity', 'InfiltrationResult'];

    function InfiltrationResultDeleteController($uibModalInstance, entity, InfiltrationResult) {
        var vm = this;

        vm.infiltrationResult = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            InfiltrationResult.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
