(function() {
    'use strict';

    angular
        .module('infiltrationApp')
        .controller('InfiltrationEntryDetailController', InfiltrationEntryDetailController);

    InfiltrationEntryDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'InfiltrationEntry', 'InfiltrationResult', 'BuildingType', 'MapLocation'];

    function InfiltrationEntryDetailController($scope, $rootScope, $stateParams, previousState, entity, InfiltrationEntry, InfiltrationResult, BuildingType, MapLocation) {
        var vm = this;

        vm.infiltrationEntry = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('infiltrationApp:infiltrationEntryUpdate', function(event, result) {
            vm.infiltrationEntry = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
