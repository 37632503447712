(function() {
    'use strict';

    angular
        .module('infiltrationApp')
        .controller('EnergyCalcController', EnergyCalcController);

    EnergyCalcController.$inject = ['$scope', '$state', 'EnergyCalc'];

    function EnergyCalcController ($scope, $state, EnergyCalc) {
        var vm = this;
        
        vm.energyCalcs = [];

        loadAll();

        function loadAll() {
            EnergyCalc.query(function(result) {
                vm.energyCalcs = result;
            });
        }
    }
})();
