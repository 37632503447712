(function() {
    'use strict';

    angular
        .module('infiltrationApp')
        .controller('MoistureTransferController', MoistureTransferController);

    MoistureTransferController.$inject = ['$scope', '$state', 'MoistureTransfer'];

    function MoistureTransferController ($scope, $state, MoistureTransfer) {
        var vm = this;
        
        vm.moistureTransfers = [];

        loadAll();

        function loadAll() {
            MoistureTransfer.query(function(result) {
                vm.moistureTransfers = result;
            });
        }
    }
})();
