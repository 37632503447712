/**
 * Created by g9a on 9/12/16.
 */
(function() {
    'use strict';

    angular
        .module('infiltrationApp')
        .factory('UnitConversion', UnitConversion)
        .constant("Constants", {
            CurrencyType: {
                USD: 1,
                CAD: 1.29,
                CNY: 6.64
            },
            ConversionFactor: 0.2034,
            FeetKbtuConversionFactor: 1.021,
            MeterKbtuConversionFactor: 35.314667
        });


    UnitConversion.$inject = ['Constants'];

    function UnitConversion () {

        // Natural gas costs: $/m^3 or $/1000ft^3


        var service = {
            // For Floor area
            toSquareFeet: toSquareFeet,
            toMetersSquared: toMetersSquared,
            toMetersCubed: toMetersCubed,
            toFeetCubed: toFeetCubed,
            // For leakage
            toLeakageMeters : toLeakageMeters,
            toLeakageFeet : toLeakageFeet,
            // For gas savings
            kbtuToFeetCubed : kbtuToFeetCubed,
            kbtuToMeterCubed : kbtuToMeterCubed,
            toCny: toCny,
            toUsd: toUsd,
            toCad: toCad
        };

        return service;

        function kbtuToMeterCubed (input) {
            // This is to convert the result that has been calculated as kbtu to a savings by m^3
            //var retVal = (input * 0.0277344 * 1000).toFixed(2);
            var retVal = (input / 35.31742)/1.021;
            console.log("gas savings to meters cubed: input ", input, " output ", retVal);
            return retVal;
        }

        function kbtuToFeetCubed (input) {
            // This is to convert the result that has been calculated as kbtu to a savings by 1000ft^3
            var retVal = (input * 0.97943).toFixed(2);
            console.log("gas savings to 1000 feet cubed: input ", input, " output ", retVal);
            return retVal;
        }

        function toSquareFeet (input) {
            // from m^2 to ft^2
            var retVal = (input / 0.09290304).toFixed(2);
            console.log("to square feet: input ", input, " output ", retVal);
            return retVal;
        }

        function toMetersSquared (input) {
            // from ft^2 to m^2
            var retVal = (input * 0.09290304).toFixed(2);
            console.log("to meters squared: input ", input, " output ", retVal);
            return retVal;
        }

        function toMetersCubed (input) {
            // from ft^3 to m^3
            //var retVal = (input / 35.315) / 1000;
            var retVal = (input / 28.3168).toFixed(2);
            console.log("to meters cubed: input ", input, " output ", retVal);
            return retVal;
        }

        function toFeetCubed (input) {
            // from m^3 to ft^3
            //var retVal = (input * 35.315) / 1000;
            var retVal = (input * 28.3168).toFixed(2);
            console.log("to feet cubed: input ", input, " output ", retVal);
            return retVal;
        }

        function toLeakageFeet (input) {
            // From  L/sec*m^2 to CFM/ft^2
            var output = (input * 0.19685).toFixed(2);
            console.log("to leakage feet: input ", input, " output ", output);
            return output;
        }

        function toLeakageMeters (input) {
            // From CFM/ft^2 to L/sec*m^2
            var output = (input/0.19685).toFixed(2);
            console.log("to leakage meters: input ", input, " output ", output);
            return output;
        }

        // Below is not used at all

        function toUsd (input, fromUnit) {
            // To United States Dollar
            var output = (input * fromUnit).toFixed(2);
            return output;
        }

        function toCad (input, fromUnit) {
            // To Canadian Dollars
            var output = toUsd(fromUnit)/Constants.CurrencyType.CAD;
            return output;
        }

        function toCny (input, fromUnit) {
            // To Chinese Yuan
            var output = toUsd(fromUnit)/Constants.CurrencyType.CNY;
            return output;
        }
    }
})();
