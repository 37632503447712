/**
 * Created by g9a on 2/5/16.
 */
(function () {
    'use strict';

    angular
        .module('infiltrationApp')
        .controller('InfiltrationController', InfiltrationController);

    InfiltrationController.$inject = ['$scope', '$localStorage', '$sce', 'Principal', '$state', 'LoginService', 'BuildingType', 'InfiltrationEntry', 'UnitConversion'];

    function InfiltrationController($scope, $localStorage, $sce, Principal, $state, LoginService, BuildingType, InfiltrationEntry, UnitConversion) {
        var vm = this;
        $scope.buildingTypes = [];

        $scope.infiltrationEntry = $localStorage.infiltrationEntry;
        $scope.infiltrationResult = $localStorage.infiltrationResult;

        $scope.markers = $localStorage.markers;
        $scope.marker = $scope.markers[0];
        $scope.costElectricity = $scope.marker.cost_electricity;
        $scope.costGas = $scope.marker.cost_gas;
        $scope.retrofitLeakage = 2.0;
        $scope.basecaseLeakage = 5.42;

        $scope.areaUnits = $localStorage.areaUnits;
        $scope.areaUnit = $scope.areaUnits[0];
        $scope.leakageUnits = $localStorage.leakageUnits;
        $scope.leakageUnit = $scope.leakageUnits[0];
        $scope.gasCostUnitss = $localStorage.gasCostUnitss;
        $scope.gasCostUnits = $scope.gasCostUnitss[0];
        initMap();
        loadBuildingTypes();

        $scope.map = {
            zoom: 2.8,
            key: 'AIzaSyC559Prz4D31xzlAdoxx4aue5b1rXwnrzE',
            center: {
               // latitude: $scope.marker.coords.latitude,
                latitude: 50.4419,
                longitude: -100.1419
               // longitude: $scope.marker.coords.longitude
            }
        };

        $scope.onMarkerClick = function (mkr, even, obj) {
            $scope.marker = obj;
            $scope.infiltrationEntry.mapLocation = $scope.marker;
            $scope.costElectricity = $scope.marker.cost_electricity;
            $scope.costGas = $scope.marker.cost_gas;
            var currentCenter = Object.create($scope.marker.coords);
            $scope.map.center = currentCenter;
            $scope.map.zoom = 5;
        };

        Principal.identity().then(function (account) {
            $scope.account = account;
            $scope.isAuthenticated = Principal.isAuthenticated;
        });

        $scope.submit = function () {
            $scope.infiltrationEntry.mapLocation = $scope.marker;
            $scope.infiltrationEntry.basecaseLeakage = $scope.basecaseLeakage;
            $scope.infiltrationEntry.area = $scope.floorArea;
            $scope.infiltrationEntry.costElectricity = $scope.costElectricity;
            $scope.infiltrationEntry.costGas = $scope.costGas;
            $scope.infiltrationEntry.retrofittedLeakage = $scope.retrofitLeakage;
            $scope.infiltrationEntry.buildingType = $scope.buildingType;
            $scope.infiltrationEntry.areaUnit = $scope.areaUnit.val;
            $scope.infiltrationEntry.leakageUnit = $scope.leakageUnit.val;
            $scope.infiltrationEntry.gasCostUnits = $scope.gasCostUnits.val;
            $scope.savingsResults = true;
            save();
        };

        function save() {
            $scope.isSaving = true;
            if (!angular.isUndefined($scope.infiltrationEntry.id)) {
                InfiltrationEntry.update($scope.infiltrationEntry, onUpdateSuccess, onUpdateError);
            } else {
                InfiltrationEntry.save($scope.infiltrationEntry, onSaveSuccess, onUpdateError);
            }
        }

        function onUpdateSuccess (result) {
            $scope.$emit('infiltrationApp:infiltrationEntryUpdate', result);
            $scope.infiltrationEntry.id = result.id;
            $localStorage.infiltrationEntry = result;
            $scope.isSaving = false;
        }

        function onSaveSuccess (result) {
            $scope.$emit('infiltrationApp:infiltrationEntrySave', result);
            $scope.infiltrationEntry.id = result.id;
            $localStorage.infiltrationEntry = result;
            $scope.isSaving = false;
        }

        function onSaveError () {
            console.log('On Save Error');
            $scope.isSaving = false;
        }

        function onUpdateError () {
            console.log('On Update Error');
            $scope.isSaving = false;
        }


        $scope.changeSelectedMarker = function (geolocation) {
            $scope.marker = geolocation;
            $scope.costElectricity = $scope.marker.cost_electricity;
            $scope.costGas = $scope.marker.cost_gas;
            $scope.map.center = $scope.marker.coords;
            $scope.map.zoom = 5;
        };

        $scope.changeBuildingType = function (buildingType) {
            // $scope.floorArea = $scope.buildingType.floorArea;
            // $scope.basecaseLeakage = parseFloat($scope.buildingType.leakageRate);
//            if (!$scope.hasOwnProperty('infiltrationEntry')) {
                if(buildingType.shortName === "residential"){
                    $scope.infiltrationEntry.leakageUnit = 'ACH50';
                    $scope.basecaseLeakage = $scope.buildingType.leakageRate;
                    $scope.leakageUnit = $localStorage.leakageUnits[2];
                }
                if ($scope.areaUnit.val === "M2") {
                    $scope.infiltrationEntry.areaUnit = 'M2';
                    $scope.floorArea = parseFloat($scope.buildingType.descriptionText);
                    $scope.areaUnit = $localStorage.areaUnits[1];
                } else {
                    $scope.infiltrationEntry.areaUnit = 'SQFT';
                    $scope.floorArea = parseFloat($scope.buildingType.floorArea);
                    $scope.areaUnit = $localStorage.areaUnits[0];
                }
                if ($scope.leakageUnit.val === "CFMSQFT") {
                    $scope.infiltrationEntry.leakageUnit = "CFMSQFT";
                    $scope.basecaseLeakage = parseFloat($scope.buildingType.altLeakageRate);
                    $scope.leakageUnit = $scope.leakageUnits[1]
                } else if ($scope.leakageUnit.val === "LPERSECM2") {
                    $scope.infiltrationEntry.leakageUnit = "LPERSECM2";
                    $scope.basecaseLeakage = parseFloat($scope.buildingType.leakageRate);
                    $scope.leakageUnit = $localStorage.leakageUnits[0];
                } else if ($scope.leakageUnit.val === "ACH50") {
                    $scope.infiltrationEntry.leakageUnit = "ACH50";
                    $scope.basecaseLeakage = parseFloat($scope.buildingType.leakageRate);
                    $scope.leakageUnit = $localStorage.leakageUnits[2];
                }


//            }
//            ;
        };

        function initMap (){
            if ($scope.hasOwnProperty('infiltrationEntry')) {
                if ($scope.infiltrationEntry.hasOwnProperty('mapLocation')) {
                    $scope.marker = _.find($scope.markers, {'id': $scope.infiltrationEntry.mapLocation.id});
                }
            }
        };
        function loadBuildingTypes () {
            BuildingType.query(function (result) {
                $scope.buildingTypes = result;
                $scope.buildingType = $scope.buildingTypes[0];
                $scope.floorArea = $scope.buildingType.floorArea;
                $scope.basecaseLeakage = parseFloat($scope.buildingType.leakageRate);
                $scope.areaUnit = $localStorage.areaUnits[0];
                $scope.leakageUnit = $localStorage.leakageUnits[0];

                $scope.gasCostUnits = $localStorage.gasCostUnitss[0];

            });
        };
        function initInfiltrationValues () {
            // if ($scope.hasOwnProperty('infiltrationEntry')) {
            //     if ($scope.infiltrationEntry.hasOwnProperty('buildingType')) {
            //         $scope.buildingType = $scope.infiltrationEntry.buildingType;
            //     }
            //     if ($scope.infiltrationEntry.hasOwnProperty('area')) {
            //         $scope.floorArea = $scope.infiltrationEntry.area;
            //     }
            //     if ($scope.infiltrationEntry.hasOwnProperty('basecaseLeakage')) {
            //         $scope.basecaseLeakage = $scope.infiltrationEntry.basecaseLeakage;
            //     }
            //     if ($scope.infiltrationEntry.hasOwnProperty('costElectricity')) {
            //         $scope.costElectricity = $scope.infiltrationEntry.costElectricity;
            //     }
            //     if ($scope.infiltrationEntry.hasOwnProperty('costGas')) {
            //         $scope.costGas = $scope.infiltrationEntry.costGas;
            //     }
            //     if ($scope.infiltrationEntry.hasOwnProperty('retrofittedLeakage')) {
            //         $scope.retrofitLeakage = $scope.infiltrationEntry.retrofittedLeakage;
            //     }
            //     if ($scope.infiltrationEntry.hasOwnProperty('leakageUnit')) {
            //         $scope.leakageUnit = _.find($scope.leakageUnits, { 'val': $scope.infiltrationEntry.leakageUnit});
            //     }
            //     if ($scope.infiltrationEntry.hasOwnProperty('areaUnit')) {
            //         $scope.areaUnit = _.find($scope.areaUnits, { 'val': $scope.infiltrationEntry.areaUnit});
            //     }
            //     if ($scope.infiltrationEntry.hasOwnProperty('gasCostUnits')) {
            //         $scope.gasCostUnits = _.find($scope.gasCostUnitss, { 'val': $scope.infiltrationEntry.gasCostUnits});
            //     }
            // }
        }

        initInfiltrationValues();

        $scope.changeGasCostUnits = function(obj) {
            if (obj.val === "M3") {
                $scope.infiltrationEntry.gasCostUnits = 'M3';
                $scope.costGas = parseFloat(UnitConversion.toMetersCubed($scope.costGas));
            } else {
                $scope.infiltrationEntry.gasCostUnits = 'FT3';
                $scope.costGas = parseFloat(UnitConversion.toFeetCubed($scope.costGas));
            }
        };
        $scope.changeLeakageUnits = function(obj) {
            if (obj.val === "CFMSQFT") {
                $scope.infiltrationEntry.leakageUnit = "CFMSQFT";
                $scope.basecaseLeakage = parseFloat(UnitConversion.toLeakageFeet($scope.basecaseLeakage));
                $scope.retrofitLeakage = parseFloat(UnitConversion.toLeakageFeet($scope.retrofitLeakage));
                $scope.leakageUnit = $scope.leakageUnits[1];
            } else if (obj.val === "LPERSECM2"){
                $scope.infiltrationEntry.leakageUnit = "LPERSECM2";
                $scope.basecaseLeakage = parseFloat(UnitConversion.toLeakageMeters($scope.basecaseLeakage));
                $scope.retrofitLeakage = parseFloat(UnitConversion.toLeakageMeters($scope.retrofitLeakage));
                $scope.leakageUnit = $scope.leakageUnits[0];
            } else if (obj.val === "ACH50"){
                $scope.infiltrationEntry.leakageUnit = "ACH50";
                $scope.basecaseLeakage = $scope.buildingType.leakageRate;
                $scope.retrofitLeakage = $scope.retrofitLeakage;
                $scope.leakageUnit = $scope.leakageUnits[2];
            }


        };
        $scope.changeAreaUnits = function(obj) {
            if (obj.val === "M2") {
                $scope.infiltrationEntry.areaUnit = "M2";
                $scope.floorArea = parseFloat(UnitConversion.toMetersSquared($scope.floorArea));
            } else {
                $scope.infiltrationEntry.areaUnit = "SQFT";
                $scope.floorArea = parseFloat(UnitConversion.toSquareFeet($scope.floorArea));
            }

        };
        $scope.htmlAdText = function(text){ return $sce.trustAsHtml(text); };

    }

})();
