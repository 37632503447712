(function() {
    'use strict';

    angular
        .module('infiltrationApp')
        .controller('MapLocationController', MapLocationController);

    MapLocationController.$inject = ['$scope', '$state', 'MapLocation'];

    function MapLocationController ($scope, $state, MapLocation) {
        var vm = this;
        
        vm.mapLocations = [];

        loadAll();

        function loadAll() {
            MapLocation.query(function(result) {
                vm.mapLocations = result;
            });
        }
    }
})();
